'use strict';

module.exports = {
     ApiMainUrl: 'https://api.prod.tamiralife.com/',
     ApiBaseUrl: 'https://api.prod.tamiralife.com/api/backend/',
     //ApiBaseUrl:'http://localhost:8080/api/backend/',
     frontEndUrl: 'https://www.tamiralife.com/',
     cacheType: 'sessionStorage',
     debug:false,
     s3Url : "https://tamira-live.s3-ap-south-1.amazonaws.com",
     bucketName : "tamira-live",
     region : "ap-south-1",
     accessKeyId : "AKIAYHR4X7QTGUAULWOR",
     secretAccessKey : "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",
     editorKey : "syi46egl19ax4jd173sv2vb56nfloqubu47m5qlo6wikqo1d"     
};