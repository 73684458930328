/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';
import S3 from "react-aws-s3";
import { Link } from "react-router-dom";
import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDBadge";

import { useDispatch, useSelector } from 'react-redux';
import { getAllBlogAction, deleteBlogAction } from '../../../store/BlogManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state => state.blog);
  const dispatch = useDispatch();

  const [blogs, setBlogs] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);

  useEffect(() => {
    dispatch(getAllBlogAction());
  }, []);

  useEffect(() => {
    //Lists
    if (state.blogs.length > 0) {
      setBlogs(state.blogs);           
    }else{
      setBlogs([]);
    }
  }, [state]);

  const handleDeleteBlog = (index) => {
    dispatch(deleteBlogAction(blogs[index].post_id));
    dispatch(getAllBlogAction());
    // const config = {
    //   bucketName: bucketName,
    //   dirName: "blog",
    //   region: region,
    //   accessKeyId: accessKeyId,
    //   secretAccessKey: secretAccessKey
    // };
    // const ReactS3Client = new S3(config);
    // ReactS3Client
    // .deleteFile(s3Url + "/blog/" + blogs[index].upload_image)
    // .then((response) => {
    //   dispatch(getAllBlogAction());
    // })
    // .catch(err => console.error(err))
  }

  const handleEditBlog = (blog) => {
    //console.log(blog);
    props.setName(blog.title);
    props.setShowName(blog.post_image);
    props.setCategoryId(blog.category_id);    
    props.setDate(new Date());
    props.setDescription(blog.description);
    props.setDescription1(blog.description);
    props.setShortDescription(blog.short_description);
    props.setShortDescription1(blog.short_description);
    props.setBlogId(blog.post_id);
    props.setMetaTag(blog.meta_title);
    props.setMetaDescription(blog.meta_description);
    props.setShowActive(true);
    props.handleOpen();
    props.setAlt(blog.alt_tag);
    props.setSlug(blog.slug);
    props.setRelatedUrl(blog.related_link);
    props.setRelatedBtnText(blog.related_link_text);
    props.setAuthor(blog.author);
    
    // var element = document.querySelector('.css-1wnsr1i');
    // element.classList.add("my-class");
  };

  useEffect(() => {

    const allblogs1 = blogs.map((blog, index) => {
      // var fullDate = blog.add_date.split("T");
      // var DateOnly = fullDate[0];
      //console.log(blog);
      return(
        {
          blogImage: <Author image={s3Url + "/blog/" + blog.post_image} name={blog.name} />,
          title: blog.title,
          category: blog.category_name,
          author: blog.author,
          blogLink: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          <a href={"https://www.tamiralife.com/blog/"+blog.slug} target="_blank"><MDButton className="customBtn">View</MDButton></a>
        </MDTypography>,
          action: (
            <>
              <MDBox ml={-1}>
                <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditBlog(blog)}>
                  Edit
                </MDButton>
              </MDBox>
              <MDBox ml={-1}>
                <MDButton style={buttonStyle} onClick={() => handleDeleteBlog(index)}>
                  Delete
                </MDButton>
              </MDBox>
            </>
          ),
        }
      )
    });
    setAllBlogs(allblogs1);
  }, [blogs]);

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Image", accessor: "blogImage", width: "20%", align: "left" },
      { Header: "Title", accessor: "title", width: "10%", align: "left", wordWrap: "wrap" },
      // { Header: "Description", accessor: "description", align: "center" },
      // { Header: "Short Description", accessor: "shortdescription", align: "center" },
      { Header: "Category", accessor: "category", align: "center" },
      { Header: "Author", accessor: "author", width: "20%", align: "left" },
      { Header: "Link", accessor: "blogLink", width: "20%", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allBlogs
  };
}
