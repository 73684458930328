// global types

//Banner
export const GET_ALL_BANNERS = "GET_ALL_BANNERS";
export const EDIT_BANNER = "EDIT_BANNER";
export const SAVE_BANNER = "SAVE_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";


//Body Part
export const GET_ALL_BODY_PARTS = "GET_ALL_BODY_PARTS";
export const GET_SINGLE_BODY_PART = "GET_SINGLE_BODY_PART";
export const EDIT_BODY_PART = "EDIT_BODY_PART";
export const SAVE_BODY_PART = "SAVE_BODY_PART";
export const DELETE_BODY_PART = "DELETE_BODY_PART";

//Body Part Symptom
export const GET_ALL_BODY_PART_SYMPTOMS = "GET_ALL_BODY_PART_SYMPTOMS";
export const GET_SINGLE_BODY_PART_SYMPTOM = "GET_SINGLE_BODY_PART_SYMPTOM";
export const EDIT_BODY_PART_SYMPTOM = "EDIT_BODY_PART_SYMPTOM";
export const SAVE_BODY_PART_SYMPTOM = "SAVE_BODY_PART_SYMPTOM";
export const DELETE_BODY_PART_SYMPTOM = "DELETE_BODY_PART_SYMPTOM";
export const SAVE_BODY_PART_SYMPTOM_DISEASE = "SAVE_BODY_PART_SYMPTOM_DISEASE";

//Condition
export const GET_ALL_CONDITIONS = "GET_ALL_CONDITIONS";
export const EDIT_CONDITION = "EDIT_CONDITION";
export const SAVE_CONDITION = "SAVE_CONDITION";
export const DELETE_CONDITION = "DELETE_CONDITION";

//Service
export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const EDIT_SERVICE = "EDIT_SERVICE";
export const SAVE_SERVICE = "SAVE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";

//Specialization
export const GET_ALL_SPECIALIZATIONS = "GET_ALL_SPECIALIZATIONS";
export const EDIT_SPECIALIZATION = "EDIT_SPECIALIZATION";
export const SAVE_SPECIALIZATION = "SAVE_SPECIALIZATION";
export const DELETE_SPECIALIZATION = "DELETE_SPECIALIZATION";

//Docotr
export const GET_ALL_DOCTORS = "GET_ALL_DOCTORS";
export const GET_SINGLE_DOCTOR = "GET_SINGLE_DOCTOR";
export const EDIT_DOCTOR = "EDIT_DOCTOR";
export const SAVE_DOCTOR = "SAVE_DOCTOR";
export const DELETE_DOCTOR = "DELETE_DOCTOR";

//Clinic
export const GET_ALL_CLINICS = "GET_ALL_CLINICS";
export const EDIT_CLINIC = "EDIT_CLINIC";
export const SAVE_CLINIC = "SAVE_CLINIC";
export const DELETE_CLINIC = "DELETE_CLINIC";

//Doctor Clinic
export const GET_ALL_DOCTOR_CLINICS = "GET_ALL_DOCTOR_CLINICS";
export const EDIT_DOCTOR_CLINIC = "EDIT_DOCTOR_CLINIC";
export const SAVE_DOCTOR_CLINIC = "SAVE_DOCTOR_CLINIC";
export const DELETE_DOCTOR_CLINIC = "DELETE_DOCTOR_CLINIC";

//Doctor Education
export const GET_ALL_DOCTOR_EDUCATIONS = "GET_ALL_DOCTOR_EDUCATIONS";
export const EDIT_DOCTOR_EDUCATION = "EDIT_DOCTOR_EDUCATION";
export const SAVE_DOCTOR_EDUCATION = "SAVE_DOCTOR_EDUCATION";
export const DELETE_DOCTOR_EDUCATION = "DELETE_DOCTOR_EDUCATION";

//Doctor Membership
export const GET_ALL_DOCTOR_MEMBERSHIPS = "GET_ALL_DOCTOR_MEMBERSHIPS";
export const EDIT_DOCTOR_MEMBERSHIP = "EDIT_DOCTOR_MEMBERSHIP";
export const SAVE_DOCTOR_MEMBERSHIP = "SAVE_DOCTOR_MEMBERSHIP";
export const DELETE_DOCTOR_MEMBERSHIP = "DELETE_DOCTOR_MEMBERSHIP";

//Keyword
export const GET_ALL_KEYWORDS = "GET_ALL_KEYWORDS";
export const LIKE_KEYWORDS = "LIKE_KEYWORDS";
export const EDIT_KEYWORD = "EDIT_KEYWORD";
export const SAVE_KEYWORD = "SAVE_KEYWORD";
export const DELETE_KEYWORD = "DELETE_KEYWORD";

//Major Category
export const GET_ALL_MAJOR_CATEGORYS = "GET_ALL_MAJOR_CATEGORYS";
export const EDIT_MAJOR_CATEGORY = "EDIT_MAJOR_CATEGORY";
export const SAVE_MAJOR_CATEGORY = "SAVE_MAJOR_CATEGORY";
export const DELETE_MAJOR_CATEGORY = "DELETE_MAJOR_CATEGORY";

//Disease
export const GET_SINGLE_DISEASE = "GET_SINGLE_DISEASE";
export const GET_ALL_DISEASES = "GET_ALL_DISEASES";
export const EDIT_DISEASE = "EDIT_DISEASE";
export const SAVE_DISEASE = "SAVE_DISEASE";
export const DELETE_DISEASE = "DELETE_DISEASE";

//Disease Treatment
export const GET_ALL_DISEASE_TREATMENTS = "GET_ALL_DISEASE_TREATMENTS";
export const EDIT_DISEASE_TREATMENT = "EDIT_DISEASE_TREATMENT";
export const SAVE_DISEASE_TREATMENT = "SAVE_DISEASE_TREATMENT";
export const DELETE_DISEASE_TREATMENT = "DELETE_DISEASE_TREATMENT";

//City Treatment
export const GET_ALL_CITY_TREATMENTS = "GET_ALL_CITY_TREATMENTS";
export const EDIT_CITY_TREATMENT = "EDIT_CITY_TREATMENT";
export const SAVE_CITY_TREATMENT = "SAVE_CITY_TREATMENT";
export const DELETE_CITY_TREATMENT = "DELETE_CITY_TREATMENT";

//City Center
export const GET_ALL_CITY_CENTERS = "GET_ALL_CITY_CENTERS";
export const EDIT_CITY_CENTER = "EDIT_CITY_CENTER";
export const SAVE_CITY_CENTER = "SAVE_CITY_CENTER";
export const DELETE_CITY_CENTER = "DELETE_CITY_CENTER";

//Medicine
export const GET_ALL_MEDICINES = "GET_ALL_MEDICINES";
export const EDIT_MEDICINE = "EDIT_MEDICINE";
export const SAVE_MEDICINE = "SAVE_MEDICINE";
export const DELETE_MEDICINE = "DELETE_MEDICINE";

//Treatment
export const GET_ALL_TREATMENTS = "GET_ALL_TREATMENTS";
export const GET_ALL_TREATMENT_BODYPARTS = "GET_ALL_TREATMENT_BODYPARTS";
export const GET_SINGLE_TREATMENT = "GET_SINGLE_TREATMENT";
export const EDIT_TREATMENT = "EDIT_TREATMENT";
export const SAVE_TREATMENT = "SAVE_TREATMENT";
export const DELETE_TREATMENT = "DELETE_TREATMENT";

//Treatment Cause
export const GET_ALL_CAUSES = "GET_ALL_CAUSES";
export const EDIT_CAUSE = "EDIT_CAUSE";
export const SAVE_CAUSE = "SAVE_CAUSE";
export const DELETE_CAUSE = "DELETE_CAUSE";

//Treatment Option
export const GET_ALL_OPTIONS = "GET_ALL_OPTIONS";
export const EDIT_OPTION = "EDIT_OPTION";
export const SAVE_OPTION = "SAVE_OPTION";
export const DELETE_OPTION = "DELETE_OPTION";

//Treatment Technique
export const GET_ALL_TECHNIQUES = "GET_ALL_TECHNIQUES";
export const EDIT_TECHNIQUE = "EDIT_TECHNIQUE";
export const SAVE_TECHNIQUE = "SAVE_TECHNIQUE";
export const DELETE_TECHNIQUE = "DELETE_TECHNIQUE";

//Treatment Area
export const GET_ALL_AREAS = "GET_ALL_AREAS";
export const EDIT_AREA = "EDIT_AREA";
export const SAVE_AREA = "SAVE_AREA";
export const DELETE_AREA = "DELETE_AREA";

//Treatment Location
export const GET_ALL_LOCATIONS = "GET_ALL_LOCATIONS";
export const GET_SINGLE_LOCATION = "GET_SINGLE_LOCATION";
export const GET_ALL_ACTIVE_LOCATIONS = "GET_ALL_ACTIVE_LOCATIONS";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const SAVE_LOCATION = "SAVE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";

//Symptom Treatment
export const GET_ALL_SYMPTOM = "GET_ALL_SYMPTOM";
export const GET_SINGLE_SYMPTOM = "GET_SINGLE_SYMPTOM";
export const EDIT_SYMPTOM = "EDIT_SYMPTOM";
export const SAVE_SYMPTOM = "SAVE_SYMPTOM";
export const DELETE_SYMPTOM = "DELETE_SYMPTOM";

//Before After Treatment
export const GET_ALL_BEFORE_AFTERS = "GET_ALL_BEFORE_AFTERS";
export const EDIT_BEFORE_AFTER = "EDIT_BEFORE_AFTER";
export const SAVE_BEFORE_AFTER = "SAVE_BEFORE_AFTER";
export const DELETE_BEFORE_AFTER = "DELETE_BEFORE_AFTER";
export const DELETE_BEFORE_AFTERS = "DELETE_BEFORE_AFTERS";

//Faq
export const GET_ALL_FAQS = "GET_ALL_FAQS";
export const EDIT_FAQ = "EDIT_FAQ";
export const SAVE_FAQ = "SAVE_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";

//Url
export const GET_ALL_URLS = "GET_ALL_URLS";
export const EDIT_URL = "EDIT_URL";
export const SAVE_URL = "SAVE_URL";
export const DELETE_URL = "DELETE_URL";

//Testimonial
export const GET_ALL_TESTIMONIALS = "GET_ALL_TESTIMONIALS";
export const EDIT_TESTIMONIAL = "EDIT_TESTIMONIAL";
export const SAVE_TESTIMONIAL = "SAVE_TESTIMONIAL";
export const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL";

//Video
export const GET_ALL_VIDEOS = "GET_ALL_VIDEOS";
export const EDIT_VIDEO = "EDIT_VIDEO";
export const SAVE_VIDEO = "SAVE_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";

//Authentication
export const SET_AUTHENTICATION = "SET_AUTHENTICATION";

// Blog
export const GET_ALL_POST = "GET_ALL_POST";
export const EDIT_POST = "EDIT_POST";
export const SAVE_POST = "SAVE_POST";
export const DELETE_POST = "DELETE_POST";

// Blog Category
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const SAVE_CATEGORY = "SAVE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";


// Newsletter Category
export const GET_ALL_NEWSLETTER = "GET_ALL_NEWSLETTER";
export const EDIT_NEWSLETTER = "EDIT_NEWSLETTER";
export const SAVE_NEWSLETTER = "SAVE_NEWSLETTER";
export const DELETE_NEWSLETTER = "DELETE_NEWSLETTER";

// City Treatment Video
export const GET_ALL_CITY_TREATMENT_VIDEO = "GET_ALL_CITY_TREATMENT_VIDEO";
export const EDIT_CITY_TREATMENT_VIDEO = "EDIT_CITY_TREATMENT_VIDEO";
export const SAVE_CITY_TREATMENT_VIDEO = "SAVE_CITY_TREATMENT_VIDEO";
export const DELETE_CITY_TREATMENT_VIDEO = "DELETE_CITY_TREATMENT_VIDEO";

// City Treatment Before after
export const GET_ALL_CITY_TREATMENT_BEFORE_AFTER = "GET_ALL_CITY_TREATMENT_BEFORE_AFTER";
export const EDIT_CITY_TREATMENT_BEFORE_AFTER = "EDIT_CITY_TREATMENT_BEFORE_AFTER";
export const SAVE_CITY_TREATMENT_BEFORE_AFTER = "SAVE_CITY_TREATMENT_BEFORE_AFTER";
export const DELETE_CITY_TREATMENT_BEFORE_AFTER = "DELETE_CITY_TREATMENT_BEFORE_AFTER";

// City Treatment Faq
export const GET_ALL_CITY_TREATMENT_FAQ = "GET_ALL_CITY_TREATMENT_FAQ";
export const EDIT_CITY_TREATMENT_FAQ = "EDIT_CITY_TREATMENT_FAQ";
export const SAVE_CITY_TREATMENT_FAQ = "SAVE_CITY_TREATMENT_FAQ";
export const DELETE_CITY_TREATMENT_FAQ = "DELETE_CITY_TREATMENT_FAQ";

// City Treatment Testimonial
export const GET_ALL_CITY_TREATMENT_TESTIMONIAL = "GET_ALL_CITY_TREATMENT_TESTIMONIAL";
export const EDIT_CITY_TREATMENT_TESTIMONIAL = "EDIT_CITY_TREATMENT_TESTIMONIAL";
export const SAVE_CITY_TREATMENT_TESTIMONIAL = "SAVE_CITY_TREATMENT_TESTIMONIAL";
export const DELETE_CITY_TREATMENT_TESTIMONIAL = "DELETE_CITY_TREATMENT_TESTIMONIAL";

// template 
export const GET_ALL_TEMPLATE = "GET_ALL_TEMPLATE";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";

// Iv Therapy
export const GET_ALL_THERAPY = "GET_ALL_THERAPY";
export const EDIT_THERAPY = "EDIT_THERAPY";
export const SAVE_THERAPY = "SAVE_THERAPY";
export const DELETE_THERAPY = "DELETE_THERAPY";

// Iv Therapy Benefit
export const GET_ALL_THERAPY_BENEFIT = "GET_ALL_THERAPY_BENEFIT";
export const EDIT_THERAPY_BENEFIT = "EDIT_THERAPY_BENEFIT";
export const SAVE_THERAPY_BENEFIT = "SAVE_THERAPY_BENEFIT";
export const DELETE_THERAPY_BENEFIT = "DELETE_THERAPY_BENEFIT";

// Iv Therapy Ingredient
export const GET_ALL_THERAPY_INGREDIENT = "GET_ALL_THERAPY_INGREDIENT";
export const EDIT_THERAPY_INGREDIENT = "EDIT_THERAPY_INGREDIENT";
export const SAVE_THERAPY_INGREDIENT = "SAVE_THERAPY_INGREDIENT";
export const DELETE_THERAPY_INGREDIENT = "DELETE_THERAPY_INGREDIENT";


//Iv Therapy Testimonial
export const GET_ALL_IV_THERAPY_TESTIMONIALS = "GET_ALL_IV_THERAPY_TESTIMONIALS";
export const EDIT_IV_THERAPY_TESTIMONIAL = "EDIT_IV_THERAPY_TESTIMONIAL";
export const SAVE_IV_THERAPY_TESTIMONIAL = "SAVE_IV_THERAPY_TESTIMONIAL";
export const DELETE_IV_THERAPY_TESTIMONIAL = "DELETE_IV_THERAPY_TESTIMONIAL";

//Iv Therapy Faq
export const GET_ALL_IV_THERAPY_FAQS = "GET_ALL_IV_THERAPY_FAQS";
export const EDIT_IV_THERAPY_FAQ = "EDIT_IV_THERAPY_FAQ";
export const SAVE_IV_THERAPY_FAQ = "SAVE_IV_THERAPY_FAQ";
export const DELETE_IV_THERAPY_FAQ = "DELETE_IV_THERAPY_FAQ";

//Page
export const GET_ALL_PAGES = "GET_ALL_PAGES";
export const EDIT_PAGES = "EDIT_PAGES";
export const SAVE_PAGES = "SAVE_PAGES";
export const DELETE_PAGES = "DELETE_PAGES";