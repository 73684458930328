import React, { useState, useEffect, useRef } from 'react';
import S3 from "react-aws-s3";
import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../config';
import {  Link, useParams } from "react-router-dom";

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, Typography, Breadcrumbs } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import doctorMembershipTableData from "layouts/doctorMembership/data/doctorMembershipTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getSingleDoctorAction } from '../../store/DoctorManagement/action';
import { getAllDoctorMembershipActions, saveDoctorMembershipAction, editDoctorMembershipAction } from '../../store/DoctorMembershipManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Membership() {
  let { doctorId } = useParams();
  const state = useSelector(state=> state.doctorMembership);
  const state1 = useSelector(state=> state.doctor);
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  const [file, setFile] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [showName, setShowName] = React.useState("");
  //Form Data
  const [name, setName] = useState("");
  const [doctorMembershipId, setDoctorMembershipId] = useState("");
  const [doctor, setDoctor] = useState({});

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    setDoctorMembershipId("");
    setShowActive(false);
    setOpen(false);
  };

  const { columns, rows } = doctorMembershipTableData({handleOpen, setName, setShowName, doctorId, setDoctorMembershipId, setShowActive });

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g,"");  
    var random = ("" + Math.random()).substring(2, 8); 
    var random_number = timestamp+random;  
    return random_number;
  };

  const fileInput = useRef();

  const handleFileInput = (e) => {
    // handle validations
    //alert(1);
    setFile(e.target.files[0]);
    setFileName(getRandomFileName()+e.target.files[0].name);
  };

  useEffect(() => {
    dispatch(getSingleDoctorAction(doctorId));       
  }, [doctorId]);

  useEffect(() => {
    //Lists
    if (state1.doctor !== undefined) {
      //console.log(state1.doctor);
      setDoctor(state1.doctor);
    }   
  }, [state1]);

  const uploadDoctorMembership = (event) => {
    event.preventDefault();
    const config = {
      bucketName : bucketName,
      dirName : "doctorMembership",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (doctorMembershipId)
    {
      if(fileName != "")
      {
        ReactS3Client
        .deleteFile(s3Url+"/doctorMembership/"+showName)
        .then((response) => {
          ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            //setS3FileUrl(data.location);
            const formData = {
              "name" : name,
              "doctor_id" : doctorId,
              "upload_certificate" : fileName
            }
            dispatch(editDoctorMembershipAction(formData, doctorMembershipId));
            setDoctorMembershipId("");
            dispatch(getAllDoctorMembershipActions(doctorId)); 
            handleClose();
          })
        })
        .catch(err => console.error(err))
      }else{
        const formData = {
          "name" : name,
          "doctor_id" : doctorId,
          "upload_certificate" : fileName
        }
        dispatch(editDoctorMembershipAction(formData, doctorMembershipId));
        setDoctorMembershipId("");
        dispatch(getAllDoctorMembershipActions(doctorId)); 
        handleClose();
      }
    }else{
      ReactS3Client.uploadFile(file, fileName)
      .then(data => {
        //console.log(data.location);
        //setS3FileUrl(data.location);
        const formData = {
          "name" : name,
          "doctor_id" : doctorId,
          "upload_certificate" : fileName
        }
        dispatch(saveDoctorMembershipAction(formData));
        dispatch(getAllDoctorMembershipActions(doctorId)); 
        handleClose();
      })
    }
  }

  return (
    <DashboardLayout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/dashboard">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          to="/doctors"
        >
          {doctor.first_name+" "+doctor.last_name}
        </Link>
        <Typography color="text.primary">Membership</Typography>
      </Breadcrumbs>
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Membership
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadDoctorMembership}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Name</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput} accept="application/pdf" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Image</InputLabel>
                      <Box component="img" src={s3Url+"/doctorMembership/"+showName}></Box>
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Memberships
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Membership;