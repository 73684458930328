import { combineReducers } from "redux";
import bannerManagementReducer from "./BannerManagement/reducer";
import bodyPartManagementReducer from "./BodyPartManagement/reducer";
import bodyPartSymptomManagementReducer from "./BodyPartSymptomManagement/reducer";
import conditionManagementReducer from "./ConditionManagement/reducer";
import doctorManagementReducer from "./DoctorManagement/reducer";
import clinicManagementReducer from "./ClinicManagement/reducer";
import serviceManagementReducer from "./ServiceManagement/reducer";
import specializationManagementReducer from "./SpecializationManagement/reducer";
import doctorClinicManagementReducer from "./DoctorClinicManagement/reducer";
import doctorEducationManagement from "./DoctorEducationManagement/reducer";
import doctorMembershipManagement from "./DoctorMembershipManagement/reducer";
import faqManagementReducer from "./FaqManagement/reducer";
import videoManagementReducer from "./VideoManagement/reducer";
import testimonialManagementReducer from "./TestimonialManagement/reducer";
import keywordManagementReducer from "./KeywordManagement/reducer";
import majorCategoryManagementReducer from "./MajorCategoryManagement/reducer";
import authenticationReducer from "./Authentication/reducer";
import treatmentManagementReducer from "./TreatmentManagement/reducer";
import diseaseManagementReducer from "./DiseaseManagement/reducer";
import diseaseTreatmentManagementReducer from "./DiseaseTreatmentManagement/reducer";
import cityTreatmentManagementReducer from "./CityTreatmentManagement/reducer";
import cityCenterManagementReducer from "./CityCenterManagement/reducer";
import medicineManagementReducer from "./MedicineManagement/reducer";
import causeManagementReducer from "./CauseManagement/reducer";
import optionManagementReducer from "./OptionManagement/reducer";
import techniqueManagementReducer from "./TechniqueManagement/reducer";
import areaManagementReducer from "./AreaManagement/reducer";
import symptomManagementReducer from "./SymptomManagement/reducer";
import beforeAfterManagementReducer from "./BeforeAfterManagement/reducer";
import locationManagementReducer from "./LocationManagement/reducer";
import TreatmentUrlReducer from './TreatmentLinkManagement/reducer';
import blogManagementReducer from './BlogManagement/reducer';
import categoryManagementReducer from './PostCategoryManagement/reducer';
import newsletterManagementReducer from './NewsletterManagement/reducer';
import cityTreatmentVideoManagementReducer from './CityTreatmentVideoManagement/reducer';
import cityTreatmentBeforeAfterManagementReducer from './CityTreatmentBeforeAfterManagement/reducer';
import cityTreatmentFaqManagementReducer from './CityTreatmentFaqManagement/reducer';
import cityTreatmentTestomialManagementReducer from './CityTreatmentTestomialManagement/reducer';
import TemplateManagement from './TemplateManagement/reducer';
import IvTherapyManagement from './IvTherapyManagement/reducer';
import IvTherapyBenefitManagement from './IvTherapyBenefitManagement/reducer';
import IvTherapyIngredientManagement from './IvTherapyIngredientsManagement/reducer';
import ivTestimonialManagementReducer from "./IvTherapyTestimonialManagement/reducer";
import ivFaqManagementReducer from "./IvTherapyFaqManagement/reducer";
import PageManagementReducer from "./PageManagement/reducer";

export default combineReducers({
  bannerManagement: bannerManagementReducer,
  bodyPart: bodyPartManagementReducer, 
  bodyPartSymptom: bodyPartSymptomManagementReducer,  
  condition: conditionManagementReducer,
  doctor: doctorManagementReducer,
  clinic: clinicManagementReducer,
  service: serviceManagementReducer,
  specialization: specializationManagementReducer,
  doctorClinic: doctorClinicManagementReducer,
  doctorEducation: doctorEducationManagement,
  doctorMembership: doctorMembershipManagement,
  faq: faqManagementReducer,
  treatmentUrl:TreatmentUrlReducer,
  video: videoManagementReducer,
  testimonial: testimonialManagementReducer,
  keyword: keywordManagementReducer,
  majorCategory: majorCategoryManagementReducer,
  authentication: authenticationReducer,
  treatment: treatmentManagementReducer,
  disease: diseaseManagementReducer,
  diseaseTreatment: diseaseTreatmentManagementReducer,
  cityTreatment: cityTreatmentManagementReducer,
  cityCenter:cityCenterManagementReducer,
  medicine: medicineManagementReducer,
  cause: causeManagementReducer,
  option: optionManagementReducer,
  technique: techniqueManagementReducer,
  area: areaManagementReducer,
  symptom: symptomManagementReducer,
  beforeAfter: beforeAfterManagementReducer,
  location:locationManagementReducer,
  blog:blogManagementReducer,
  category:categoryManagementReducer,
  newsletter:newsletterManagementReducer,
  cityTreatmentVideo:cityTreatmentVideoManagementReducer,
  cityTreatmentBeforeAfter:cityTreatmentBeforeAfterManagementReducer,
  cityTreatmentFaq:cityTreatmentFaqManagementReducer,
  cityTreatmentTestimonial:cityTreatmentTestomialManagementReducer,
  template:TemplateManagement,
  ivTherapy:IvTherapyManagement,
  ivTherapyBenefit:IvTherapyBenefitManagement,
  ivTherapyIngredient:IvTherapyIngredientManagement,
  ivTestimonial: ivTestimonialManagementReducer,
  ivFaq: ivFaqManagementReducer,
  page:PageManagementReducer
});
