import React, { useState, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import S3 from "react-aws-s3";

import { ApiBaseUrl, accessKeyId, secretAccessKey, region, s3Url, bucketName, editorKey } from '../../config';
// @mui material components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, TextareaAutosize } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import treatmentTableData from "layouts/treatment/data/treatmentTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getAllBodyPartsAction } from '../../store/BodyPartManagement/action';
import { getAllKeywordsAction } from '../../store/KeywordManagement/action';
import { getAllMedicinesAction } from '../../store/MedicineManagement/action';
import { getAllTreatmentsAction, editTreatmentAction, saveTreatmentAction } from '../../store/TreatmentManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let keywords = [];
let medicines = [];

export default function Treatments() {
  const state = useSelector(state => state.keyword);
  const state1 = useSelector(state => state.bodyPart);
  const medicineState = useSelector(state => state.medicine);
  const dispatch = useDispatch();

  const [showActive, setShowActive] = React.useState(false);
  const [bodyParts, setBodyParts] = React.useState([]);
  const [allBodyParts, setAllBodyParts] = React.useState([]);
  const [keywordValue, setKeywordValue] = React.useState([]);
  const [medicineValue, setMedicineValue] = React.useState([]);
  const [image, setImage] = React.useState([]);
  const [imageName, setImageName] = React.useState("");

  const [mobileImage, setMobileImage] = React.useState([]);
  const [mobileImageName, setMobileImageName] = React.useState("");
  const [showMobileImageName, setShowMobileImageName] = React.useState("");


  const [credentialImage, setCredentialImage] = React.useState([]);
  const [credentialImageName, setCredentialImageName] = React.useState("");
  const [showCredentialImageName, setShowCredentialImageName] = React.useState("");

  const [brochureImage, setBrochureImage] = React.useState([]);
  const [brochureImageName, setBrochureImageName] = React.useState("");
  const [showBrochureImageName, setShowBrochureImageName] = React.useState("");

  const [showName, setShowName] = React.useState("");
  const [s3fileUrl, setS3FileUrl] = React.useState("");

  //Form Data
  const [popular, setPopular] = useState(false);
  const [name, setName] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [shortDescription1, setShortDescription1] = useState("");
  const [causeDescription, setCauseDescription] = useState("");
  const [causeDescription1, setCauseDescription1] = useState("");
  const [description, setDescription] = useState("");
  const [description1, setDescription1] = useState("");
  const [costDescription, setCostDescription] = useState("");
  const [costDescription1, setCostDescription1] = useState("");
  const [whoCanGo, setWhoCanGoDescription] = useState("");
  const [whoCanGo1, setWhoCanGoDescription1] = useState("");
  const [recoveryDescription, setRecoveryDescription] = useState("");
  const [recoveryDescription1, setRecoveryDescription1] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [symptoms1, setSymptoms1] = useState("");
  const [careBefore, setCareBefore] = useState("");
  const [careBefore1, setCareBefore1] = useState("");
  const [riskComplication, setRiskComplication] = useState("");
  const [riskComplication1, setRiskComplication1] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [doctorMetaTitle, setDoctorMetaTitle] = useState("");
  const [doctorMetaDescription, setDoctorMetaDescription] = useState("");
  const [beforeAfterMetaTitle, setBeforeAfterMetaTitle] = useState("");
  const [beforeAfterMetaDescription, setBeforeAfterMetaDescription] = useState("");
  const [credentialMandatoryDoctor, setCredentialMandatoryDoctor] = useState("");
  const [credentialMandatoryDoctor1, setCredentialMandatoryDoctor1] = useState("");
  const [treatmentKeywords, setTreatmentKeywords] = React.useState([]);
  const [treatmentMedicines, setTreatmentMedicines] = React.useState([]);
  const [bodyPartId, setBodyPartId] = useState("");
  const [treatmentId, setTreatmentId] = useState("");
  const [menuTitle, setMenuTitle] = React.useState("");
  const [open, setOpen] = useState(false);
  const [bannerAltText, setBannerAltText] = useState('');
  const [credentialAltText, setCredentialAltText] = useState('');

  const [videoMetaTitle, setVideoMetaTitle] = useState('');
  const [videoMetaDescription, setVideoMetaDescription] = useState('');

  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const handleOpen = () => setOpen(true);

  const { columns, rows } = treatmentTableData({ handleOpen, setPopular, setName, setMenuTitle, setBannerText, setShortDescription, setShortDescription1, setCauseDescription, setCauseDescription1, setDescription, setDescription1, setCostDescription, setCostDescription1, setWhoCanGoDescription, setWhoCanGoDescription1, setRecoveryDescription, setRecoveryDescription1, setMetaTitle, setMetaDescription, setDoctorMetaTitle, setDoctorMetaDescription, setBeforeAfterMetaTitle, setBeforeAfterMetaDescription, setSymptoms, setSymptoms1, setCareBefore, setCareBefore1, setRiskComplication, setRiskComplication1, setCredentialMandatoryDoctor, setCredentialMandatoryDoctor1, setShowName, setShowCredentialImageName, setShowBrochureImageName, setTreatmentKeywords, setTreatmentMedicines, setBodyPartId, setTreatmentId, setShowActive, setShowMobileImageName, setCredentialAltText, setBannerAltText, setVideoMetaTitle, setVideoMetaDescription, setMaxPrice, setMinPrice });

  const fileInput = useRef();

  //Keyword
  useEffect(() => {
    dispatch(getAllKeywordsAction());
  }, []);

  useEffect(() => {
    //Lists
    if (state.keywords !== undefined) {
      keywords = state.keywords;
    }
  }, [state]);

  //Medicine
  useEffect(() => {
    dispatch(getAllMedicinesAction());
  }, []);

  useEffect(() => {
    //Lists
    if (medicineState.medicines !== undefined) {
      medicines = medicineState.medicines;
    }
  }, [medicineState]);

  useEffect(() => {
    dispatch(getAllBodyPartsAction());
  }, []);

  useEffect(() => {
    //Lists
    if (state1.bodyparts !== undefined) {
      //console.log(state1.bodyPart);
      setBodyParts(state1.bodyparts);
    }
  }, [state1]);

  useEffect(() => {
    //console.log(bodyParts);
    const bodyparts1 = bodyParts.map((bodyPart, index) => (
      <MenuItem key={index} value={bodyPart.body_part_id}>{bodyPart.name}</MenuItem>
    ));
    //console.log(bodyparts1);
    setAllBodyParts(bodyparts1);
  }, [bodyParts]);

  const handleClose = () => {
    setPopular(false);
    setName("");
    setBannerText("");
    setShortDescription("");
    setCauseDescription("");
    setDescription("");
    setMenuTitle("");
    setCostDescription("");
    setWhoCanGoDescription("");
    setRecoveryDescription("");
    setSymptoms("");
    setCareBefore("");
    setRiskComplication("");
    setCredentialMandatoryDoctor("");
    setShowName("");
    setMetaTitle("");
    setMetaDescription("");
    setDoctorMetaTitle("");
    setDoctorMetaDescription("");
    setBeforeAfterMetaTitle("");
    setBeforeAfterMetaDescription("");
    setTreatmentKeywords([]);
    setTreatmentMedicines([]);
    setBodyPartId("");
    setTreatmentId("");
    setImageName("");
    setMobileImageName("");
    setCredentialImageName("");
    setBrochureImageName("");
    setShowActive(false);
    setOpen(false);
    setCredentialAltText('');
    setVideoMetaTitle('');
    setVideoMetaDescription('');
    setBannerAltText('');
    setMaxPrice('');
    setMinPrice('');
  };

  const getRandomimageName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    var random = ("" + Math.random()).substring(2, 8);
    var random_number = timestamp + random;
    return random_number;
  }

  const handleImageInput = (e) => {
    setImage(e.target.files[0]);
    setImageName(getRandomimageName() + e.target.files[0].name);
  }

  const handleCredentialImageInput = (e) => {
    setCredentialImage(e.target.files[0]);
    setCredentialImageName(getRandomimageName() + e.target.files[0].name);
  }

  const handleBrochureInput = (e) => {
    setBrochureImage(e.target.files[0]);
    setBrochureImageName(getRandomimageName() + e.target.files[0].name);
  }

  // 
  const handleMobileInput = (e) => {
    setMobileImage(e.target.files[0]);
    setMobileImageName(getRandomimageName() + e.target.files[0].name);
  }

  const getKeyword = (keyword) => {
    //alert(keyword);
    axios.get(ApiBaseUrl + "keyword/like-keyword/sk")
      .then(result1 => {
        topFilms = result1.data.data;
        //setBanners(result1.data.data);
      })
  }

  const handlePopularChange = (event) => {
    setPopular(event.target.value);
  };

  const uploadTreatment = (event) => {
    event.preventDefault();
    const config = {
      bucketName: bucketName,
      dirName: "treatment",
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey
    };
    const ReactS3Client = new S3(config);
    let credentialimageName1 = "";
    let brochureimageName1 = "";
    let mobilebannerName1 = "";

    if (credentialImageName != "") {
      if (showCredentialImageName == "" || showCredentialImageName == null) {
        ReactS3Client.uploadFile(credentialImage, credentialImageName)
          .then(data1 => {
            credentialimageName1 = credentialImageName;
            //setMainImageName(imageName);
          })
        credentialimageName1 = credentialImageName;
      } else {
        ReactS3Client
          .deleteFile(showCredentialImageName)
          .then((response) => {
            ReactS3Client.uploadFile(credentialImage, credentialImageName)
              .then(data1 => {
                //setMainImageName(imageName);
              })
          })
          .catch(err => console.error(err));
        credentialimageName1 = credentialImageName;
      }
    } else {
      credentialimageName1 = showCredentialImageName;
    }

    if (brochureImageName != "") {
      // console.log(showBrochureImageName, brochureImage, brochureImageName);
      if (showBrochureImageName == "") {
        ReactS3Client.uploadFile(brochureImage, brochureImageName)
          .then(data1 => {
            brochureimageName1 = brochureImageName;
            //setMainImageName(imageName);
          })
        brochureimageName1 = brochureImageName;
      } else {
        ReactS3Client
          .deleteFile(showBrochureImageName)
          .then((response) => {
            ReactS3Client.uploadFile(brochureImage, brochureImageName)
              .then(data1 => {
                //setMainImageName(imageName);
              })
          })
          .catch(err => console.error(err));
        brochureimageName1 = brochureImageName;
      }
    } else {
      brochureimageName1 = showBrochureImageName;
    }

    if (mobileImageName != "") {

      if (showMobileImageName == "") {
        ReactS3Client.uploadFile(mobileImage, mobileImageName)
          .then(data1 => {
            mobilebannerName1 = mobileImageName;
            //setMainImageName(imageName);
          })
        mobilebannerName1 = mobileImageName;
      } else {
        ReactS3Client
          .deleteFile(showMobileImageName)
          .then((response) => {
            ReactS3Client.uploadFile(mobileImage, mobileImageName)
              .then(data1 => {
                //setMainImageName(imageName);
              })
          })
          .catch(err => console.error(err));
        mobilebannerName1 = mobileImageName;
      }
    } else {
      mobilebannerName1 = showMobileImageName;
    }


    //console.log("mobile image" , mobilebannerName1);
    if (treatmentId) {
      if (imageName != "") {
        ReactS3Client
          .deleteFile(s3Url + "/treatment/" + showName)
          .then((response) => {
            ReactS3Client.uploadFile(image, imageName)
              .then(data => {
                //console.log(data.location);
                //setS3FileUrl(data.location);
                const formData = {
                  "body_part_id": bodyPartId,
                  "popular": popular,
                  "title": name,
                  "menu_title": menuTitle,
                  "banner_text": bannerText,
                  "description": description,
                  "cause": causeDescription,
                  "short_description": shortDescription,
                  "cost_description": costDescription,
                  "who_can_go": whoCanGo,
                  "recovery_after_surgery": recoveryDescription,
                  "symptoms": symptoms,
                  "care_before": careBefore,
                  "risk_complication": riskComplication,
                  "credential_mandatory_doctor": credentialMandatoryDoctor,
                  "meta_title": metaTitle,
                  "meta_description": metaDescription,
                  "doctor_meta_title": doctorMetaTitle,
                  "doctor_meta_description": doctorMetaDescription,
                  "before_after_meta_title": beforeAfterMetaTitle,
                  "before_after_meta_description": beforeAfterMetaDescription,
                  "image": imageName,
                  "credential_image": credentialimageName1,
                  "brochure": brochureimageName1,
                  "mobile_banner": mobilebannerName1,
                  "keywordValue": keywordValue,
                  "medicineValue": medicineValue,
                  "banner_alt_text": bannerAltText,
                  "credential_alt_text": credentialAltText,
                  "video_meta_title": videoMetaTitle,
                  "video_meta_description": videoMetaDescription,
                  "max_price":maxPrice,
                  "min_price":minPrice
                }
                dispatch(editTreatmentAction(formData, treatmentId, treatmentId));
                setTreatmentId("");
                dispatch(getAllTreatmentsAction());
                handleClose();
              })
          })
          .catch(err => console.error(err))
      } else {
        const formData = {
          "body_part_id": bodyPartId,
          "popular": popular,
          "title": name,
          "menu_title": menuTitle,
          "banner_text": bannerText,
          "description": description,
          "cause": causeDescription,
          "short_description": shortDescription,
          "cost_description": costDescription,
          "who_can_go": whoCanGo,
          "recovery_after_surgery": recoveryDescription,
          "symptoms": symptoms,
          "care_before": careBefore,
          "risk_complication": riskComplication,
          "credential_mandatory_doctor": credentialMandatoryDoctor,
          "meta_title": metaTitle,
          "meta_description": metaDescription,
          "doctor_meta_title": doctorMetaTitle,
          "doctor_meta_description": doctorMetaDescription,
          "before_after_meta_title": beforeAfterMetaTitle,
          "before_after_meta_description": beforeAfterMetaDescription,
          "image": showName,
          "credential_image": credentialimageName1,
          "brochure": brochureimageName1,
          "mobile_banner": mobilebannerName1,
          "keywordValue": keywordValue,
          "medicineValue": medicineValue,
          "banner_alt_text": bannerAltText,
          "credential_alt_text": credentialAltText,
          "video_meta_title": videoMetaTitle,
          "video_meta_description": videoMetaDescription,
          "max_price":maxPrice,
          "min_price":minPrice
        }
        dispatch(editTreatmentAction(formData, treatmentId, treatmentId));
        setTreatmentId("");
        dispatch(getAllTreatmentsAction());
        handleClose();
      }
    } else {
      if (imageName != "") {
        ReactS3Client.uploadFile(image, imageName)
          .then(data => {
            //console.log(data.location);
            //setS3FileUrl(data.location);
            const formData = {
              "body_part_id": bodyPartId,
              "title": name,
              "menu_title": menuTitle,
              "banner_text": bannerText,
              "description": description,
              "cause": causeDescription,
              "short_description": shortDescription,
              "cost_description": costDescription,
              "who_can_go": whoCanGo,
              "recovery_after_surgery": recoveryDescription,
              "symptoms": symptoms,
              "care_before": careBefore,
              "risk_complication": riskComplication,
              "credential_mandatory_doctor": credentialMandatoryDoctor,
              "credential_image": credentialimageName1,
              "brochure": brochureimageName1,
              "mobile_banner": mobilebannerName1,
              "image": imageName,
              "banner_alt_text": bannerAltText,
              "credential_alt_text": credentialAltText,
              "video_meta_title": videoMetaTitle,
              "video_meta_description": videoMetaDescription
            }
            dispatch(saveTreatmentAction(formData, treatmentId));
            dispatch(getAllTreatmentsAction());
            handleClose();
          })
      } else {
        let credentialimageName1 = "";
        let brochureImageName1 = "";
        let mobilebannerName1 = "";

        if (credentialImage == "") {
          ReactS3Client.uploadFile(credentialImage, credentialImageName)
            .then(data1 => {
              credentialimageName1 = credentialImageName;
              //setMainImageName(imageName);
            })
          credentialimageName1 = credentialImage;
        } else {
          credentialimageName1 = credentialImage;
        }

        if (brochureImage == "") {
          ReactS3Client.uploadFile(brochureImage, brochureImageName)
            .then(data1 => {
              brochureImageName1 = brochureImageName;
              //setMainImageName(imageName);
            })
          brochureImageName1 = credentialImage;
        } else {
          brochureImageName1 = brochureImage;
        }

        if (mobileImage == "") {
          ReactS3Client.uploadFile(mobileImage, mobileImageName)
            .then(data1 => {
              mobilebannerName1 = mobileImageName;
              //setMainImageName(imageName);
            })
          mobilebannerName1 = mobileImage;
        } else {
          mobilebannerName1 = mobileImage;
        }


        const formData = {
          "body_part_id": bodyPartId,
          "title": name,
          "menu_title": menuTitle,
          "banner_text": bannerText,
          "description": description,
          "cause": causeDescription,
          "short_description": shortDescription,
          "cost_description": costDescription,
          "who_can_go": whoCanGo,
          "recovery_after_surgery": recoveryDescription,
          "symptoms": symptoms,
          "care_before": careBefore,
          "risk_complication": riskComplication,
          "credential_mandatory_doctor": credentialMandatoryDoctor,
          "credential_image": credentialimageName1,
          "brochure": brochureimageName1,
          "mobile_banner": mobilebannerName1,
          "banner_alt_text": bannerAltText,
          "credential_alt_text": credentialAltText,
          "image": "",
          "video_meta_title": videoMetaTitle,
          "video_meta_description": videoMetaDescription
        }
        dispatch(saveTreatmentAction(formData, treatmentId));
        dispatch(getAllTreatmentsAction());
        handleClose();
      }
    }
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Treatment
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal c-treatment-modal"
            disableAutoFocus={true} //for disable modal
            disableEnforceFocus={true} //for disable modal
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadTreatment}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Body Part</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Body Part"
                        value={bodyPartId}
                        onChange={(e) => setBodyPartId(e.target.value)}
                      >
                        {allBodyParts}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label1">Popular</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Popular"
                        value={popular}
                        onChange={handlePopularChange}
                      >
                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Name</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Menu Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={menuTitle} onChange={(e) => setMenuTitle(e.target.value)} />
                    </FormControl>
                  </Grid>
                  {/* SEO */}
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Meta Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Meta Description</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Doctor Meta Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={doctorMetaTitle} onChange={(e) => setDoctorMetaTitle(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Doctor Meta Description</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={doctorMetaDescription} onChange={(e) => setDoctorMetaDescription(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Before After Meta Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={beforeAfterMetaTitle} onChange={(e) => setBeforeAfterMetaTitle(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Before After Meta Description</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={beforeAfterMetaDescription} onChange={(e) => setBeforeAfterMetaDescription(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Video Meta Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={videoMetaTitle} onChange={(e) => setVideoMetaTitle(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Video Meta Description</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={videoMetaDescription} onChange={(e) => setVideoMetaDescription(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Banner Text</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={bannerText} onChange={(e) => setBannerText(e.target.value)} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Banner Alt Text</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={bannerAltText} onChange={(e) => setBannerAltText(e.target.value)} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Credential Alt Text</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={credentialAltText} onChange={(e) => setCredentialAltText(e.target.value)} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Short Description</InputLabel>
                      {/* <Input id="description" aria-describedby="my-helper-text" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} /> */}
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setShortDescription(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={shortDescription1}
                        // init={{
                        //   height: 500,
                        //   menubar:true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Cause Description</InputLabel>
                      {/* <Input id="description" aria-describedby="my-helper-text" value={causeDescription} onChange={(e) => setCauseDescription(e.target.value)} /> */}
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(value) => setCauseDescription(value)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={causeDescription1}
                        // init={{
                        //   height: 500,
                        //   menubar:true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}

                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Symptoms</InputLabel>
                      {/* <Input id="symptoms" aria-describedby="my-helper-text" value={symptoms} onChange={(e) => setSymptoms(e)} /> */}
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setSymptoms(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={symptoms1}
                        // init={{
                        //   height: 500,
                        //   menubar:true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Treatment Description</InputLabel>
                      {/* <Input id="description" aria-describedby="my-helper-text" value={description} onChange={(e) => setDescription(e)} /> */}

                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setDescription(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={description1}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Cost of Treatments</InputLabel>
                      {/* <Input id="description" aria-describedby="my-helper-text" value={costDescription} onChange={(e) => setCostDescription(e)} /> */}
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setCostDescription(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={costDescription1}
                        // init={{
                        //   height: 500,
                        //   menubar:true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Who can go for Treatments</InputLabel>
                      {/* <Input id="description" aria-describedby="my-helper-text" value={whoCanGo} onChange={(e) => setWhoCanGoDescription(e)} /> */}
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setWhoCanGoDescription(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={whoCanGo1}
                        // init={{
                        //   height: 500,
                        //   menubar:true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Recovery After Surgery</InputLabel>
                      {/* <Input id="description" aria-describedby="my-helper-text" value={recoveryDescription} onChange={(e) => setRecoveryDescription(e)} /> */}
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setRecoveryDescription(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={recoveryDescription1}
                        // init={{
                        //   height: 500,
                        //   menubar:true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Care Before</InputLabel>
                      {/* <Input id="care_before" aria-describedby="my-helper-text" value={careBefore} onChange={(e) => setCareBefore(e)} /> */}
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setCareBefore(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={careBefore1}
                        // init={{
                        //   height: 500,
                        //   menubar:true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Risk Complication</InputLabel>
                      {/* <Input id="risk_complicationsk" aria-describedby="my-helper-text" value={riskComplication} onChange={(e) => setRiskComplication(e)} /> */}
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setRiskComplication(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={riskComplication1}
                        // init={{
                        //   height: 500,
                        //   menubar:true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Credential Mandatory Doctor</InputLabel>
                      {/* <Input id="credential_mandatory_doctor" aria-describedby="my-helper-text" value={credentialMandatoryDoctor} onChange={(e) => setCredentialMandatoryDoctor(e)} /> */}
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setCredentialMandatoryDoctor(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={credentialMandatoryDoctor1}
                        // init={{
                        //   height: 500,
                        //   menubar:true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Credential Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleCredentialImageInput} />
                    </FormControl>
                  </Grid>
                  {showCredentialImageName != null && (
                    <Grid item xs={12} className={showActive ? "show" : "hide"}>
                      <FormControl>
                        <InputLabel htmlFor="my-input">Existing Credential Image</InputLabel>
                        <Box component="img" src={s3Url + "/treatment/" + showCredentialImageName} style={{ width: "100", height: "100" }}></Box>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Banner Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleImageInput} />
                    </FormControl>
                  </Grid>
                  {showName != "" && (
                    <Grid item xs={12} className={showActive ? "show" : "hide"}>
                      <FormControl>
                        <InputLabel htmlFor="my-input">Existing Mobile Banner Image</InputLabel>
                        <Box component="img" src={s3Url + "/treatment/" + showName} style={{ width: "100", height: "100" }}></Box>
                      </FormControl>
                    </Grid>
                  )}



                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Mobile Banner</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleMobileInput} />
                    </FormControl>
                  </Grid>
                  {showMobileImageName != "" && (
                    <Grid item xs={12} className={showActive ? "show" : "hide"}>
                      <FormControl>
                        <InputLabel htmlFor="my-input">Existing Mobile Banner</InputLabel>
                        <Box component="img" src={s3Url + "/treatment/" + showMobileImageName} style={{ width: "100", height: "100" }}></Box>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Brocure</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleBrochureInput} />
                    </FormControl>
                  </Grid>
                  {showBrochureImageName != null && (
                    <Grid item xs={12} className={showActive ? "show" : "hide"}>
                      <FormControl>
                        <InputLabel htmlFor="my-input">Existing Brocure</InputLabel>
                        <a href={s3Url + "/treatment/" + showBrochureImageName} target="_blank">View File</a>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="size-small-standard-multi"
                      size="small"
                      options={keywords}
                      onChange={(event, value) => setKeywordValue(value)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={treatmentKeywords}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Keyword"
                          variant="standard"
                        //placeholder="Keyword"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="size-small-standard-multi"
                      size="small"
                      options={medicines}
                      onChange={(event, value) => setMedicineValue(value)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={treatmentMedicines}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Medicine"
                          variant="standard"
                        //placeholder="Keyword"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Minimum Price</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={minPrice} onChange={(e) => setMinPrice(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Maximum Price</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} />
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12} className='c-treatment-table'>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Treatments
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  canSearch={true}
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}