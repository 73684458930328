/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import { frontEndUrl } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllCityTreatmentsAction, deleteCityTreatmentAction } from '../../../store/CityTreatmentManagement/action';
import { Link } from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.cityTreatment);
  const dispatch = useDispatch();

  const [cityTreatments, setcityTreatments] = useState([]);
  const [allCityTreatments, setallCityTreatments] = useState([]);

  useEffect(() => {
    dispatch(getAllCityTreatmentsAction(props.cityId));       
  }, []); 

  useEffect(() => {
    //Lists
    if (state.cityTreatments !== undefined) {
      setcityTreatments(state.cityTreatments);
    }
  }, [state]);  

  const handleDeleteTreatment = (index) => {
    dispatch(deleteCityTreatmentAction(props.cityId, cityTreatments[index].city_treatment_id));   
    //dispatch(getAllCityTreatmentsAction());
  }

  const handleEditTreatment = (treatment) => {
    //console.log(technique);
    props.setCityTreatmentId(treatment.city_treatment_id);
    if(treatment.treatment_id_draft == 0 || treatment.treatment_id_draft == null)
    {
      props.setTreatmentId(treatment.treatment_id);
    }else{
      props.setTreatmentId(treatment.treatment_id_draft);
    }

    if(treatment.title_draft == null || treatment.title_draft == "")
    {
      props.setTitle(treatment.title);
    }else{
      props.setTitle(treatment.title_draft);
    }

    if(treatment.slug_draft == null || treatment.slug_draft == "")
    {
      props.setSlug(treatment.slug);
    }else{
      props.setSlug(treatment.slug_draft);
    }

    if(treatment.meta_title_draft == null || treatment.meta_title_draft == "")
    {
      props.setMetaTitle(treatment.meta_title);
    }else{
      props.setMetaTitle(treatment.meta_title_draft);
    }

    if(treatment.meta_description_draft == null || treatment.meta_description_draft == "")
    {
      props.setMetaDescription(treatment.meta_description);
    }else{
      props.setMetaDescription(treatment.meta_description_draft);
    }

    //Section 1
    if(treatment.section1_title_draft == null || treatment.section1_title_draft == "")
    {
      props.setSection1Title(treatment.section1_title);
    }else{
      props.setSection1Title(treatment.section1_title_draft);
    }
    if(treatment.section1_description_draft == null || treatment.section1_description_draft == "")
    {
      props.setSection1Description(treatment.section1_description);
      props.setSection1Description1(treatment.section1_description);
    }else{
      props.setSection1Description(treatment.section1_description_draft);
      props.setSection1Description1(treatment.section1_description_draft);
    }

    //Section 2
    if(treatment.section2_title_draft == null || treatment.section2_title_draft == "")
    {
      props.setSection2Title(treatment.section2_title);
    }else{
      props.setSection2Title(treatment.section2_title_draft);
    }
    if(treatment.section2_description_draft == null || treatment.section2_description_draft == "")
    {
      props.setSection2Description(treatment.section2_description);
      props.setSection2Description1(treatment.section2_description);
    }else{
      props.setSection2Description(treatment.section2_description_draft);
      props.setSection2Description1(treatment.section2_description_draft);
    }

    //Sction 3
    if(treatment.section3_title_draft == null || treatment.section3_title_draft == "")
    {
      props.setSection3Title(treatment.section3_title);
    }else{
      props.setSection3Title(treatment.section3_title_draft);
    }
    if(treatment.section3_description_draft == null || treatment.section3_description_draft == "")
    {
      props.setSection3Description(treatment.section3_description);
      props.setSection3Description1(treatment.section3_description);
    }else{
      props.setSection3Description(treatment.section3_description_draft);
      props.setSection3Description1(treatment.section3_description_draft);
    }

    if(treatment.banner_text_draft == null || treatment.banner_text_draft == "")
    {
      props.setBannerText(treatment.banner_text);
    }else{
      props.setBannerText(treatment.banner_text_draft);
    }

    if(treatment.banner_alt_text_draft == null || treatment.banner_alt_text_draft == "")
    {
      props.setBannerAltText(treatment.banner_alt_text);
    }else{
      props.setBannerAltText(treatment.banner_alt_text_draft);
    }

    if(treatment.banner_image_draft == null || treatment.banner_image_draft == "")
    {
      props.setShowBannerImageName(treatment.banner_image);
    }else{
      props.setShowBannerImageName(treatment.banner_image_draft);
    }

    props.setAddDateTime(treatment.add_datetime);
    props.setUpdateDateTime(treatment.update_datetime);

    props.setTreatment(treatment.treatment);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    const cityTreatment1 = cityTreatments.map((cityTreatment, index) => (
      {
        
        treatment: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {cityTreatment.treatment.title}
        </MDTypography>,
        beforeAfter: <Link to={"/location/"+props.cityId+"/"+cityTreatment.treatment_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Before & After</MDButton></Link>,
        video: <Link to={"/location/video/"+props.cityId+"/"+cityTreatment.treatment_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Video</MDButton></Link>,
        faq: <Link to={"/location/faq/"+props.cityId+"/"+cityTreatment.treatment_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Faq</MDButton></Link>,
        testimonials: <Link to={"/location/testimonials/"+props.cityId+"/"+cityTreatment.treatment_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Testimonials</MDButton></Link>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={cityTreatment.publish_status == 1? "Publish":"Draft"} color={cityTreatment.publish_status == 1? "success":"warning"} variant="gradient" size="sm" />
          </MDBox>
        ),
        view: (
          <a href={frontEndUrl+props.locationSlug+"/treatment/"+cityTreatment.slug} target="_blank"><MDBox ml={-1}>
            <MDBadge badgeContent="View" color="info" variant="gradient" size="sm" />
          </MDBox></a>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditTreatment(cityTreatment)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteTreatment(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setallCityTreatments(cityTreatment1);
  }, [cityTreatments]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Treatment", accessor: "treatment", width: "30%", align: "left" },
      { Header: "Before & After", accessor: "beforeAfter", align: "left" },
      { Header: "Video", accessor: "video", align: "left" },
      { Header: "Faq", accessor: "faq", align: "left" },
      { Header: "Testimonials", accessor: "testimonials", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "view", accessor: "view", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allCityTreatments
  };
}