import React, { useState, useEffect, useRef } from 'react';
import S3 from "react-aws-s3";
import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../config';
import {  useParams } from "react-router-dom";

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, Typography, Breadcrumbs, Link } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import clinicTableData from "layouts/doctorClinic/data/clinicTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getSingleDoctorAction } from '../../store/DoctorManagement/action';
import { getAllClinicsAction } from '../../store/ClinicManagement/action';
import { getAllDoctorClinicAction, saveDoctorClinicAction, editDoctorClinicAction } from '../../store/DoctorClinicManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Clinic() {
  let { doctorId } = useParams();
  const state = useSelector(state=> state.doctor);
  const state1 = useSelector(state=> state.clinic);
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  const [clinics, setClinics] = React.useState([]);
  const [allClinics, setAllClinics] = React.useState([]);
  //Form Data
  const [consultationFee, setConsultationFee] = useState("");
  const [timing, setTiming] = useState(0);
  const [doctor, setDoctor] = useState({});
  const [clinicId, setClinicId] = useState("");
  const [doctorClinicId, setDoctorClinicId] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setConsultationFee("");
    setTiming("");
    setClinicId("");
    setDoctorClinicId("");
    setShowActive(false);
    setOpen(false);
  };

  const { columns, rows } = clinicTableData({handleOpen, setConsultationFee, setTiming, doctorId, setClinicId, setDoctorClinicId, setShowActive });

  useEffect(() => {
    dispatch(getAllClinicsAction());       
  }, []);

  useEffect(() => {
    dispatch(getSingleDoctorAction(doctorId));       
  }, [doctorId]);

  useEffect(() => {
    //Lists
    if (state.doctor !== undefined) {
      setDoctor(state.doctor);
    }   
  }, [state]);

  useEffect(() => {
    //Lists
    if (state1.clinics !== undefined) {
      setClinics(state1.clinics);
    }   
  }, [state1]);

  useEffect(() => {    
    //console.log(clinics);
    const clinics1 = clinics.map((clinic, index) => (
      <MenuItem key={index} value={clinic.clinic_id}>{clinic.name}</MenuItem>
    ));
    //console.log(majorCategorys1);
    setAllClinics(clinics1);
  }, [clinics]);

  const uploadDoctor = (event) => {
    event.preventDefault();
    if (doctorClinicId)
    {
      const formData = {
        "clinic_id" : clinicId,
        "consultation_fee" : consultationFee,
        "timing" : timing,
        "doctor_id" : doctorId
      }
      dispatch(editDoctorClinicAction(formData, doctorClinicId));
      setDoctorClinicId("");
      dispatch(getAllDoctorClinicAction(doctorId)); 
      handleClose();
    }else{
      const formData = {
        "clinic_id" : clinicId,
        "consultation_fee" : consultationFee,
        "timing" : timing,
        "doctor_id" : doctorId
      }
      dispatch(saveDoctorClinicAction(formData));
      dispatch(getAllDoctorClinicAction(doctorId)); 
      handleClose();
    }
  }

  return (
    <DashboardLayout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/backend/dashboard">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/backend/doctors"
        >
          {doctor.first_name+" "+doctor.last_name}
        </Link>
        <Typography color="text.primary">Clinic</Typography>
      </Breadcrumbs>
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Clinic
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadDoctor}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Clinic</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Clinic"
                        value={clinicId}
                        onChange={(e) => setClinicId(e.target.value)}
                      >
                        {allClinics}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Consultation Fee</InputLabel>
                      <Input id="name" aria-describedby="my-helper-text" value={consultationFee} onChange={(e) => setConsultationFee(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Timing</InputLabel>
                      <Input id="name" aria-describedby="my-helper-text" value={timing} onChange={(e) => setTiming(e.target.value)} />
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Clinics
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Clinic;
